// sends analytics to the api
const sendAnalytics = (trackingCode: string, event: 'pageload' | 'Click'): void => {

    const sendAnalytics = process.env.REACT_APP_SEND_ANALYTICS;
    const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;

    if (sendAnalytics === 'false') {
        console.warn('analytics not sent as they are disabled in the environment config', trackingCode, event);
        return;
    }

    fetch(`${analyticsUrl}?cid=${trackingCode}&evt=${event}`)
        .then((response) => {
            console.log(`${event} tracked with code ${trackingCode}`);
        });
}


export { sendAnalytics };
