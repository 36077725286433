import type three from 'three';

/**
 * there seams to be a bug with skinned meshes in three.js where once a skinned mesh starts animating
 * and it's origin goes of the screen the model will stop being displayed.
 * This function fixes the issue by disabling frustum calling for all child Skinned meshes of the passed object
 */
const fixFrustumCullingForSkinnedMesh = (object: three.Object3D) => {
    object.traverse((node) => {
        if (node.type === 'SkinnedMesh') {
            node.frustumCulled = false;
        }
    });
}

export { fixFrustumCullingForSkinnedMesh }