
import type three from 'three';

const replaceMaterials = (object3D: three.Object3D, newMaterial: three.Material, newMaterialSkinning: three.Material, oldMaterialName: string): void => {
    
    if (object3D.type === 'Mesh' || object3D.type === 'SkinnedMesh') {
        const mesh = object3D as three.Mesh;
        if (mesh.material) {
            const materials: Array<three.Material> = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
            materials.forEach((mat: three.Material) => {
                // // @ts-ignore
                // console.log(`processing material with name ${mat.name} with type ${mat.type}`, mat?.map, mat?.color);

                if (mat.name === oldMaterialName) {
                    // console.log(`replaced material with name ${mat.name} and uuid ${mat.uuid} on mesh with name ${mesh.name}`);
                    if (mesh.type === 'Mesh') mesh.material = newMaterial;
                    else if (mesh.type === 'SkinnedMesh') mesh.material = newMaterialSkinning;
                }
                // else console.log(`didn't replaced material with name ${mat.name} and uuid ${mat.uuid} on mesh with name ${mesh.name}`);
            });
        }
    }
    if (object3D.children && object3D.children.length > 0) {
        for (let i = 0; i < object3D.children.length; i++) {
            const m = object3D.children[i];
            replaceMaterials(m, newMaterial, newMaterialSkinning, oldMaterialName);
        }
    }
}


export { replaceMaterials }