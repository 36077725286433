const getMainScene = (vertical: boolean) => {
    return `
<a-scene
    xrextras-gesture-detector
    xrextras-almost-there
    xrextras-loading
    xrextras-runtime-error
    xrweb="disableWorldTracking: true"
    renderer="colorManagement: true"
    id='scene'
>
    <a-assets>
        <a-asset-item id="flat-plane" src="assets/flat-plane.glb"></a-asset-item>
    </a-assets>


    <xrextras-capture-button capture-mode="fixed"></xrextras-capture-button>

    <xrextras-capture-config
        max-duration-ms="5000"
        max-dimension="1280"
        enable-end-card="true"
        cover-image-url=""
        end-card-call-to-action=""
        short-link=""
        footer-image-url=""
        watermark-image-url=""
        watermark-max-width="100"
        watermark-max-height="10"
        watermark-location="bottomRight"
        file-name-prefix="screen-recording-"
        request-mic="manual"
        include-scene-audio="false"
        ></xrextras-capture-config>

        <xrextras-capture-preview
        action-button-share-text="Share"
        action-button-view-text="View"
        finalize-text="Exporting..."
    ></xrextras-capture-preview>

    <a-camera
        position="0 1.5 1.5"
        raycaster="objects: .cantap"
        cursor="fuse: false; rayOrigin: mouse;"
        id='camera'
    >
    </a-camera>
    <a-light type="directional" intensity="0.5" position="1 1 1"></a-light>
    <a-light type="ambient" intensity="1"></a-light>
    
    <!-- Note: "name:" must be set to the name of the image target uploaded to the 8th Wall Console -->
    <xrextras-named-image-target name="sunny-image" id='sunny-image'>
        <a-entity
            id="image-tracking-plane-sunny"
            gltf-model="#flat-plane"
        ></a-entity>
        <a-entity id="sunny-parent-ele">
            <a-entity
                animation-mixer
                rotation="${vertical ? '0' : '90'} 0 0"
                scale='0.4 0.4 0.4'
                id="render-texture-model-sunny"
                model-loading-component="loaded: false; gltf: assets/sunny-kickflip.glb"
            ></a-entity>
        </a-entity>
    </xrextras-named-image-target>
    
    <xrextras-named-image-target name="coco-image" id='coco-image'>
        <a-entity
            id="image-tracking-plane-coco"
            gltf-model="#flat-plane"
        ></a-entity>
        <a-entity id="coco-parent-ele">
            <a-entity
                animation-mixer
                rotation="${vertical ? '0' : '90'} 0 0"
                scale='0.4 0.4 0.4'
                id="render-texture-model-coco"
                model-loading-component="loaded: false; gltf: assets/coco-wheelie.glb"
            ></a-entity>
        </a-entity>
    </xrextras-named-image-target>

    <xrextras-named-image-target name="bud-image" id='bud-image'>
    <a-entity
        id="image-tracking-plane-bud"
        gltf-model="#flat-plane"
    ></a-entity>
    <a-entity id="bud-parent-ele">
        <a-entity
            animation-mixer
            rotation="${vertical ? '0' : '90'} 0 0"
            scale='0.4 0.4 0.4'
            id="render-texture-model-bud"
            model-loading-component="loaded: false; gltf: assets/bud-v3.glb"
        ></a-entity>
    </a-entity>
    </xrextras-named-image-target>

    <xrextras-named-image-target name="milla-image" id="milla-image">
        <a-entity
            id="image-tracking-plane-milla"
            gltf-model="#flat-plane"
        ></a-entity>
        <a-entity id="milla-parent-ele">
            <a-entity
                animation-mixer
                rotation="${vertical ? '0' : '90'} 0 0"
                scale='0.4 0.4 0.4'
                id="render-texture-model-milla"
                model-loading-component="loaded: false; gltf: assets/milla-v2.glb"
            ></a-entity>
        </a-entity>
    </xrextras-named-image-target>

    <xrextras-named-image-target name="lola-image" id='lola-image'>
    <a-entity
        id="image-tracking-plane-lola"
        gltf-model="#flat-plane"
    ></a-entity>
    <a-entity id="lola-parent-ele">
        <a-entity
            animation-mixer
            rotation="${vertical ? '0' : '90'} 0 0"
            scale='0.3 0.3 0.3'
            position='0 0.2 0'
            id="render-texture-model-lola"
            model-loading-component="loaded: false; gltf: assets/lola-v3.glb"
        ></a-entity>
    </a-entity>
    </xrextras-named-image-target>
</a-scene>
`;
}
export { getMainScene }