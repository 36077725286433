
import type three from 'three';

const getGeometry = (object3D: three.Object3D): three.BufferGeometry | null => {

    if (object3D.type === 'Mesh') {
        const mesh = object3D as three.Mesh;
        if (mesh.geometry) {
            return mesh.geometry;
        }
    }
    if (object3D.children && object3D.children.length > 0) {
        for (let i = 0; i < object3D.children.length; i++) {
            const m = object3D.children[i];
            let geo: three.BufferGeometry | null = getGeometry(m);
            if (geo !== null) return geo;
        }
    }
    return null;
}


export { getGeometry }