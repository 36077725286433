
import type three from 'three';
import type aframe from 'aframe';
import { getGeometry } from '../helpers/get-geometry';
// import { getMeshWithMaterial } from '../helpers/get-mesh-with-material';
import { replaceMaterials } from '../helpers/replace-materials';
declare let THREE: any;
declare let cv: any;

class ModelTrackingController {
    // the mesh that sits exactly on top of the target image
    private _imageTrackingMesh: three.Object3D | null = null;
    // the camera in the main scene
    private _targetCamera: three.Camera | null = null;

    // the final 3D model that the texture material is applied to 
    private _final3DModelWithTexture: three.Object3D | null = null;

    // the 2 canvases used for image transformations, (cropping etc)
    private _inputCanvas: HTMLCanvasElement;
    private _outputCanvas: HTMLCanvasElement;

    // the render resolution of the output image and the render resolution of the output texture used on them model
    private _textureRenderResolution: number;
    private _debugging: boolean = false;
    get debugging() { return this._debugging }
    set debugging(value) { this._debugging = value }


    // the material with the image mapped to it
    private _outputMaterial: three.Material | null = null;
    // the same as the above but with skinning enabled (needs to be septate material)    
    private _outputMaterialSkinning: three.Material | null = null;

    // as the camera feed's pixel array and the camera feed that is shown on screen is slightly different (the one shown to the user is cropped)
    // the cropped with needs to be stored once calculated  
    private _croppedInputWidth: number = -1;
    private _croppedInputHeight: number = -1;

    // the name of the material to replace on the model with the texture from the camera
    private _replaceMaterialName = '';

    // a reference to the camera pixel array
    private _cameraPixelArray: any;

    // open cv is defined here as the cv above has to be awaited 
    private _openCV: any = null;

    constructor(textureRenderResolution: number, replaceMaterialName: string, inputCanvas?: HTMLCanvasElement, outputCanvas?: HTMLCanvasElement) {

        // if the canvases are not provided they are created
        if (inputCanvas) {
            this._inputCanvas = inputCanvas;
        }
        else {
            this._inputCanvas = document.createElement('canvas') as unknown as HTMLCanvasElement;
        }

        if (outputCanvas) {
            this._outputCanvas = outputCanvas;
        }
        else {
            this._outputCanvas = document.createElement('canvas') as unknown as HTMLCanvasElement;
        }
        this._outputCanvas.width = textureRenderResolution;
        this._outputCanvas.height = textureRenderResolution;
        this._textureRenderResolution = textureRenderResolution;
        this._replaceMaterialName = replaceMaterialName; 
        this.loadOpenCV();
    }

    private loadOpenCV = async () => {
        const openCV = await cv;
        this._openCV = openCV;
    }

    public set imageTrackingMeshId(id: string) {
        const element = document.getElementById(id);
        if (element !== null) {
            const plane: aframe.Entity = element as aframe.Entity;
            if (plane.object3D) {
                this._imageTrackingMesh = plane.object3D;
            }
            else console.error(`element doesn't have an object3D`, plane);
        }
        else console.error(`element with id ${id} could not be found`);
    }
    public set final3DModelWithTextureId(id: string) {
        const element = document.getElementById(id);
        if (element !== null) {
            const plane: aframe.Entity = element as aframe.Entity;
            if (plane.object3D) {
                this._final3DModelWithTexture = plane.object3D;
            }
            else console.error(`element doesn't have an object3D`, plane);
        }
        else console.error(`element with id ${id} could not be found`);
    }
    public set targetCameraId(id: string) {
        const element = document.getElementById(id);
        if (element !== null) {
            const camera: aframe.Entity = element as aframe.Entity;
            if (camera.object3D) {
                this._targetCamera = camera.object3D.children[0] as three.Camera;
            }
            else console.error(`element doesn't have an object3D`, camera);
        }
        else console.error(`element with id ${id} could not be found`);
    }



    public updateCameraPixelArray = (cameraPixelArray: any) => {
        this._cameraPixelArray = cameraPixelArray;
    }

    /**
     * @description
     * called each frame, the main function used to update the render texture / render texture material
     */
    public updateRenderTargetTextureFromVideo = () => {
        if(this._openCV === null) {
            console.warn(`not updating render texture from video as open cv hasn't loaded yet it is ${this._openCV}`);
            return;
        }
        this.updateInputCanvasDimensions(this._cameraPixelArray.cols, this._cameraPixelArray.rows);

        const screenPositions = this.calculateScreenPositions();

        if (!screenPositions) {
            console.error(`screen positions could not be calculated they are ${screenPositions}`);
            return;
        }

        const returnCode = this.updateCanvases(screenPositions, this._cameraPixelArray);

        // if there was an error, 1 is used as the return code while if it worked correctly 0 is used
        if (returnCode === 1) return;

        this.updateMaterialTextures();

    }


    /**
     * @description
     * checks to see if the input canvas needs updating
     * normally only happens when the webpage loads or when the screen is rotated
     */
    private updateInputCanvasDimensions(width: number, height: number) {
        if (this._inputCanvas) {

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            if (this._inputCanvas.width !== width || this._inputCanvas.height !== height) {

                this._inputCanvas.width = width;
                this._inputCanvas.height = height;

                const cameraFeedAspectRatio = width / height;


                const screenAspectRatio = screenWidth / screenHeight;

                console.log(`set input canvas dimensions to ${width} x ${height} a ${cameraFeedAspectRatio} aspect ratio`);
                console.log(`screen dimensions are ${screenWidth} x ${screenHeight} a ${screenAspectRatio} aspect ratio`);

                if (cameraFeedAspectRatio > screenAspectRatio) {
                    console.log('8th wall is cutting pixels off the left and right');
                    const correctWidth = Math.round(height * screenAspectRatio);

                    console.log(`correct dimensions are ${correctWidth} x ${height}`);
                    this._croppedInputWidth = correctWidth;
                    this._croppedInputHeight = height;

                }
                else {
                    console.log('8th wall is cutting pixels off the top and bottom');
                    const correctHeight = Math.round(width / screenAspectRatio);

                    console.log(`correct dimensions are ${width} x ${correctHeight}`);
                    this._croppedInputWidth = width;
                    this._croppedInputHeight = correctHeight;
                }
            }
        }
        else {
            console.error(`cannot update input canvas dimensions as input canvas is ${this._inputCanvas}`);
        }
    }


    /**
     * @returns an array with 4 elements that contain the screen positions of the 4 points of the target image
     */
    private calculateScreenPositions = (): Array<three.Vector3> | null => {

        if (!this._imageTrackingMesh) return null;


        // gets the geometry of the mesh in object space
        const geometry = getGeometry(this._imageTrackingMesh as three.Mesh);
        if (!geometry) {
            console.error('error buffer geometry not found on mesh')
            return null;
        }

        // gets the positions of each of the 4 vertices in the model
        const positions: Array<three.Vector3> = []
        for (let i = 0; i < geometry.attributes.position.array.length; i += 3) {
            const x = geometry.attributes.position.array[i];
            const y = geometry.attributes.position.array[i + 1];
            const z = geometry.attributes.position.array[i + 2];
            positions.push(new THREE.Vector3(x, y, z) as three.Vector3);
        }

        // converts the positions of the vertices into world space
        const worldPositions: Array<three.Vector3> = [];
        positions.forEach(pos => {
            if (this._imageTrackingMesh)
                worldPositions.push(this._imageTrackingMesh.localToWorld(pos));
        });


        // the cropped image with and height are used as the screen to world points are based on the cropped image not the original input image
        const canvasWidth = this._croppedInputWidth;
        const canvasHeight = this._croppedInputHeight;

        // converts the world positions into screen positions
        const screenPositions: Array<three.Vector3> = [];

        worldPositions.forEach((p: three.Vector3) => {
            if (this._targetCamera) {
                let pos = p.clone();
                this._targetCamera.updateMatrixWorld();
                pos.project(this._targetCamera);

                pos.x = (pos.x + 1) / 2 * canvasWidth;
                pos.y = (1 - (pos.y + 1) / 2) * canvasHeight;
                screenPositions.push(pos);
            }
        });
        if (screenPositions.length !== 4) {
            console.error(`screenPositions doesn't have a length of 4, screen positions is `, screenPositions);
            return null;
        }
        return screenPositions;
    }


    /**
     * @description
     * takes the camera pixel array and screen positions and works out the area that is needed and crops it
     * then returns the bounding box of that crop
     */
    private updateCanvases = (screenPositions: Array<three.Vector3>, cameraPixelArray: any): number => {

        let boundingBox: three.Box2 = new THREE.Box2() as three.Box2;

        //works out the bounding square of the screen position
        screenPositions.forEach((p: three.Vector3) => {
            if (p.x > boundingBox.max.x) boundingBox.max.x = p.x;
            if (p.y > boundingBox.max.y) boundingBox.max.y = p.y;
            if (p.x < boundingBox.min.x) boundingBox.min.x = p.x;
            if (p.y < boundingBox.min.y) boundingBox.min.y = p.y;
        });

        let invalidBounds =
            // if the image is offscreen
            (boundingBox.max.x > this._croppedInputWidth && boundingBox.min.x > this._croppedInputWidth) ||
            (boundingBox.max.y > this._croppedInputHeight && boundingBox.min.y > this._croppedInputHeight) ||
            (boundingBox.max.x < 0 && boundingBox.min.x < 0) ||
            (boundingBox.max.y < 0 && boundingBox.min.y < 0) ||
            // if part of the image is significantly offscreen
            boundingBox.max.x > this._croppedInputWidth * 2 ||
            boundingBox.max.y > this._croppedInputHeight * 2 ||
            boundingBox.min.x < -this._croppedInputWidth ||
            boundingBox.min.y < -this._croppedInputHeight;


        if (invalidBounds) {
            // console.warn('not updating as console bounds are invalid', boundingBox);
            return 1;
        }


        // starts processing the input image
        let ctxInput = this._inputCanvas.getContext('2d');
        if (!ctxInput) {
            console.error('cound not get ctx from input canvas');
            return 1;
        }
        let ctxOutput = this._outputCanvas.getContext('2d');
        if (!ctxOutput) {
            console.error('cound not get ctx from output canvas');
            return 1;
        }

        const clampedArray = new Uint8ClampedArray(cameraPixelArray.pixels, cameraPixelArray.cols, cameraPixelArray.rows);
        const newImageData = new ImageData(clampedArray, cameraPixelArray.cols, cameraPixelArray.rows);

        // puts the input image in the input canvas
        ctxInput.putImageData(newImageData,
            0, 0,
            0, 0,
            this._inputCanvas.width, this._inputCanvas.height);


        // determines the 4 corner points of the cropped area
        const x1 = (this._inputCanvas.width - this._croppedInputWidth) / 2;
        const x2 = this._croppedInputWidth + x1;
        const y1 = (this._inputCanvas.height - this._croppedInputHeight) / 2;
        const y2 = this._croppedInputHeight + y1;


        // start of usage of open cv
        // based on example from this website https://docs.opencv.org/3.4/dd/d52/tutorial_js_geometric_transformations.html

        let src = this._openCV.imread(this._inputCanvas);
        let dst = new this._openCV.Mat();

        let dsize = new this._openCV.Size(this._textureRenderResolution, this._textureRenderResolution);
        // (data32F[0], data32F[1]) is the first point
        // (data32F[2], data32F[3]) is the second point
        // (data32F[4], data32F[5]) is the third point
        // (data32F[6], data32F[7]) is the fourth point
        let srcTri = this._openCV.matFromArray(4, 1, this._openCV.CV_32FC2, [
            screenPositions[2].x + x1, screenPositions[2].y + y1,
            screenPositions[3].x + x1, screenPositions[3].y + y1,
            screenPositions[0].x + x1, screenPositions[0].y + y1,
            screenPositions[1].x + x1, screenPositions[1].y + y1
        ]);
        let dstTri = this._openCV.matFromArray(4, 1, this._openCV.CV_32FC2, [
            0, 0,
            this._textureRenderResolution, 0,
            0, this._textureRenderResolution,
            this._textureRenderResolution, this._textureRenderResolution
        ]);
        let M = this._openCV.getPerspectiveTransform(srcTri, dstTri);
        // You can try more different parameters
        this._openCV.warpPerspective(src, dst, M, dsize, this._openCV.INTER_LINEAR, this._openCV.BORDER_CONSTANT, new this._openCV.Scalar());
        this._openCV.imshow(this._outputCanvas, dst);
        src.delete(); dst.delete(); M.delete(); srcTri.delete(); dstTri.delete();

        // end of usage of open cv


        // debug code, draws cropped area on the input canvas, at end of function so lines don't appear on texture on 3d model
        if (this._debugging) {
            ctxInput.beginPath();
            ctxInput.moveTo(x1, y1);
            ctxInput.lineTo(x2, y1);
            ctxInput.lineTo(x2, y2);
            ctxInput.lineTo(x1, y2);
            ctxInput.closePath();
            ctxInput.lineWidth = 5;
            ctxInput.strokeStyle = 'green';
            ctxInput.stroke();
        }

        // debug code, that draws the 4 corner points of target image on the input canvas
        if (this._debugging) {
            ctxInput.beginPath();
            ctxInput.moveTo(x1 + screenPositions[0].x, y1 + screenPositions[0].y);
            ctxInput.lineTo(x1 + screenPositions[1].x, y1 + screenPositions[1].y);
            ctxInput.lineTo(x1 + screenPositions[3].x, y1 + screenPositions[3].y);
            ctxInput.lineTo(x1 + screenPositions[2].x, y1 + screenPositions[2].y);
            ctxInput.closePath();
            ctxInput.lineWidth = 5;
            ctxInput.strokeStyle = 'red';
            ctxInput.stroke();
        }

        return 0;
    }

    private updateMaterialTextures = () => {

        if (this._outputCanvas) {

            const newCanvasTexture = new THREE.CanvasTexture(this._outputCanvas) as three.CanvasTexture;
            // sets flipY to false, for some reason it's true by default
            newCanvasTexture.flipY = false;

            // there are 2 as a septate material is needed with skinning set to true
            // please note that for some reason skinning is not part of three.js documentation or ts files


            // if the material doesn't exist create it otherwise just update the texture
            if (!this._outputMaterial) {
                this._outputMaterial = new THREE.MeshPhongMaterial({
                    map: newCanvasTexture,
                }) as three.MeshPhongMaterial;
            }
            else {
                const oldMap = (this._outputMaterial as three.MeshBasicMaterial).map;
                (this._outputMaterial as three.MeshBasicMaterial).map = newCanvasTexture;
                this._outputMaterial.needsUpdate = true;
                // deletes old map to prevent memory leaks
                if (oldMap) oldMap.dispose();
            }

            // if the material doesn't exist create it otherwise just update the texture
            if (!this._outputMaterialSkinning) {
                this._outputMaterialSkinning = new THREE.MeshPhongMaterial({
                    map: newCanvasTexture,
                }) as three.MeshPhongMaterial;
                // skinning is an undocumented property on materials but it needs to be set to tru on materials that are used on meshes that use armature animations
                //@ts-ignore
                this._outputMaterialSkinning.skinning = true;
                this._outputMaterialSkinning.needsUpdate = true;
            }
            else {
                const oldMap = (this._outputMaterialSkinning as three.MeshBasicMaterial).map;
                (this._outputMaterialSkinning as three.MeshBasicMaterial).map = newCanvasTexture;
                this._outputMaterialSkinning.needsUpdate = true;
                // deletes old map to prevent memory leaks
                if (oldMap) oldMap.dispose();
            }
        }

        // goes through each mesh or skinnedMesh in the object3D hierarchy and find the material with the name set as _replaceMaterialName and replaces them with the appropriate material
        if (this._final3DModelWithTexture && this._outputMaterial && this._outputMaterialSkinning) {
            const mesh = this._final3DModelWithTexture as three.Mesh;

            replaceMaterials(mesh, this._outputMaterial, this._outputMaterialSkinning, this._replaceMaterialName);
        }
    }
}

export { ModelTrackingController }