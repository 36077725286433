
const getUrlParams = (): any => {

    let paramsObj: any = {};
    // gets params from url
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    params.forEach((value: string, key: string) => {
        paramsObj[key] = value;
    });
    return paramsObj;
}


export { getUrlParams }