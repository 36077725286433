import './loading-screen.css';
import { HourglassEmpty } from "@material-ui/icons";
import TitleGraphic from '../../assets/title-graphic.png'
import LoadingGraphic from '../../assets/loading.png'

const LoadingScreen = (props: { modelName: string, modelNumber: number}) => {

    const {
        modelName,
        modelNumber,
    } = props;


    return (
        <div className='loading-screen-darken-background'>
            <img className='title-graphic' src={TitleGraphic} alt=''/>
            <img className='loading-graphic' src={LoadingGraphic} alt=''/>
            <div>
                <span>CHARACTER {modelNumber}</span>
            </div>
            <div>
                <span><b>{modelName}</b></span>
            </div>
            <div className='rotate'>
                <HourglassEmpty fontSize="inherit"/>
            </div>
        </div>
    );


}

export { LoadingScreen }
