import type aframe from 'aframe';

interface IModelLoadingComponent extends Partial<aframe.Component> {
    model?: aframe.Entity | null,
    createModel: Function,
    destroyModel: Function,
};

// used to only have the model loaded when needed to save memory and prevent crashes
const ModelLoadingComponent: IModelLoadingComponent = {
    schema: {
        loaded: { default: false },
        gltf: { default: '', type: 'string' }
    },
    init: function () {
        this.createModel = this.createModel.bind(this);
        this.destroyModel = this.destroyModel.bind(this);
        this.model = null;
    },
    remove() {
        this.destroyModel();
    },
    update(oldData) {
        if (this.data.loaded !== oldData.loaded) {
            if(this.data.loaded) this.createModel();
            else this.destroyModel();
        }
	},
    createModel() {
        if(this.model) return;
        // creates a child component that loads the entity 
        if(this.el) {
            this.model = document.createElement('a-entity');
            this.el?.appendChild(this.model);
            this.model.setAttribute('gltf-model', `url(${this.data.gltf})`);
        }
    },
    destroyModel() {
        // if the model exists it detaches the model and destroys it

        if(!this.model) return;

        if(this.model.parentNode) this.model.parentNode.removeChild(this.model);

        this.model.destroy();
        this.model = null;
    }
};

export { ModelLoadingComponent }