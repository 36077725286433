import React from 'react';
import './App.css';
import { AFrameScene } from './lib/aframe-scene';
import { getMainScene } from './scenes/main-scene';
import { ModelTrackingController } from './lib/model-tracking-controller';
import { getUrlParams } from './helpers/get-url-params';
import { ModelLoadingComponent } from './aframe-components/model-loading-component';
// import { VideoDisplay } from './lib/video-display/video-display';
// import type three from 'three';
import type aframe from 'aframe';
import triggerToIds from './triggerToIds.json';
import { LoadingScreen } from './lib/loading-screen/loading-screen';
import { fixFrustumCullingForSkinnedMesh } from './helpers/fix-frustum-culling-for-skined-mesh';
import { sendAnalytics } from './helpers/send-analytics';
// import { printSceneHierarchy } from './helpers/print-scene-hierarchy';

interface ProcessesCpu {
	cameraTextureReadyResult: any,
	frameStartResult: any,
	framework: any,
	processGpuResult: any
}

interface ITriggerToIds {
	[name: string]: {
		imageTrackingPlaneId: string,
		modelId: string,
		parentElementId: string,
		displayName: string,
		characterNumber: number,
		analyticsTrackingCode: string,
	}
}

interface IProps {

}

interface IState {
	loadingModelName: string | null,
}

class App extends React.Component<IProps, IState> {

	// should be a power of 2
	private textureResolution: number = 512;
	private textureName: string = 'texture';
	private updateIntervalInFrames: number = 15;
	private onlyUpdateOnTargetFound: boolean = false;
	private modelTrackingController: ModelTrackingController | null = null;

	private firstFrame: boolean = false;
	private updateInXFrames: number = -1;
	private trackingImage: boolean = false;
	private modelsLoaded: Array<string> = [];
	private currentModel: string | null = null;
	private triggerToIds: ITriggerToIds = triggerToIds;
	private mainScene: string;

	constructor(props: any) {
		super(props);
		this.state = {
			loadingModelName: null,
		}



		// if using debugging 2 debugging canvas windows will be used
		if (process.env.REACT_APP_DEBUGGING === 'true') {
			const inputCanvas = document.getElementById('input-canvas') as HTMLCanvasElement | null;
			const outputCanvas = document.getElementById('output-canvas') as HTMLCanvasElement | null;

			if (inputCanvas && outputCanvas) {
				this.modelTrackingController = new ModelTrackingController(this.textureResolution, this.textureName, inputCanvas, outputCanvas);
				this.modelTrackingController.debugging = true;
			}
		}
		if (!this.modelTrackingController) this.modelTrackingController = new ModelTrackingController(this.textureResolution, this.textureName);

		// the url param v is used to specify if the tracking image is in a vertical position such as a screen
		if (getUrlParams()['v'] === 'true') {
			this.mainScene = getMainScene(true);
		}
		else {
			this.mainScene = getMainScene(false);
		}
	}

	componentDidMount = () => {
		// defines basic functions used below functions

		// @ts-ignore
		if (window.XR8) {
			this.onXrLoaded();
		}
		else {
			window.addEventListener('xrloaded', this.onXrLoaded, { once: true, passive: true });
		}
		sendAnalytics("AVC-CB-01", "pageload");
	}




	// loads the model that is meant to be used and unloads the rest of the models
	loadModel = (modelId: string, analyticsTrackingCode: string) => {
		// loads the specified model and unloads the other models to save memory
		document.getElementById('render-texture-model-coco')?.setAttribute('model-loading-component', `loaded: ${modelId === 'render-texture-model-coco'}`);
		document.getElementById('render-texture-model-sunny')?.setAttribute('model-loading-component', `loaded: ${modelId === 'render-texture-model-sunny'}`);
		document.getElementById('render-texture-model-bud')?.setAttribute('model-loading-component', `loaded: ${modelId === 'render-texture-model-bud'}`);
		document.getElementById('render-texture-model-milla')?.setAttribute('model-loading-component', `loaded: ${modelId === 'render-texture-model-milla'}`);
		document.getElementById('render-texture-model-lola')?.setAttribute('model-loading-component', `loaded: ${modelId === 'render-texture-model-lola'}`);

		// only sends analytics if the tracked model has changed
		if(modelId !== this.currentModel) {
			sendAnalytics(analyticsTrackingCode, "Click");
			this.currentModel = modelId;
		}
	};

	// attaches the model to the camera when tracking is lost
	attachModelToCamera = (modelId: string) => {
		// finds the camera and model elements
		const character = document.getElementById(modelId) as aframe.Entity;
		const camera = document.getElementById('camera') as aframe.Entity;

		if (character && character.object3D && camera && camera.object3D) {
			camera.object3D.attach(character.object3D);
			character.object3D.position.set(0, -0.25, -1);
			// the angle depends on weather it was originally displayed in a vertical or horizontal position, needs to convert degrees to radians 
			const angle = getUrlParams()['v'] === 'true' ? 0 : -90 * (Math.PI / 180)

			character.object3D.rotation.set(angle, 0, 0);
		}
	}

	// attaches the model to the tracking image when tracking is found again
	attachModelToTrackingImage = (modelId: string, trackingImageId: string) => {
		// finds the camera and model elements
		const character = document.getElementById(modelId) as aframe.Entity;
		const trackingImage = document.getElementById(trackingImageId) as aframe.Entity;

		if (character && character.object3D && trackingImage && trackingImage.object3D && character.object3D.parent?.uuid !== trackingImage.object3D.uuid) {
			trackingImage.object3D.attach(character.object3D);
			character.object3D.position.set(0, 0, 0);
			character.object3D.rotation.set(0, 0, 0);
		}
	}


	handleXRImageFound = (e: any) => {
		// console.log("xrimagefound", e);
		this.updateInXFrames = this.updateIntervalInFrames;
		this.trackingImage = true;

		if (this.modelTrackingController) {
			this.modelTrackingController.imageTrackingMeshId = this.triggerToIds[e.detail.name].imageTrackingPlaneId;
			this.modelTrackingController.final3DModelWithTextureId = this.triggerToIds[e.detail.name].modelId;
			this.loadModel(this.triggerToIds[e.detail.name].modelId, this.triggerToIds[e.detail.name].analyticsTrackingCode);
			this.attachModelToTrackingImage(this.triggerToIds[e.detail.name].parentElementId, e.detail.name);

			// only shows loading screen if this is the first time it is loaded
			if (!this.modelsLoaded.includes(e.detail.name)) {
				this.setState({ loadingModelName: e.detail.name });
			}
		}
	};

	handleModelLoaded = () => {
		const { loadingModelName } = this.state;

		if (loadingModelName) {
			this.modelsLoaded.push(loadingModelName);
			const id: string = this.triggerToIds[loadingModelName].modelId;

			// finds the object3D and applies a fix
			const element = document.getElementById(id);
			if(element && (element as aframe.Entity).object3D) {
				fixFrustumCullingForSkinnedMesh((element as aframe.Entity).object3D);
			}
		}

		this.setState({ loadingModelName: null });
	};

	onXrLoaded = () => {
		// @ts-ignore
		if (!window.XR8) {
			return;
		}
		// @ts-ignore
		const XR8: any = window.XR8;

		if (XR8 && XR8.addCameraPipelineModule) {
			// adds pipeline module to capture the screen
			XR8.addCameraPipelineModule(XR8.CameraPixelArray.pipelineModule({ luminance: false }))

			// Adds pipeline to update the camera pixel array for the model tracking controller
			XR8.addCameraPipelineModule({
				name: 'camera-feed',
				onProcessCpu: (data: ProcessesCpu) => {
					const { processGpuResult } = data;
					const { camerapixelarray } = processGpuResult;

					if ((!camerapixelarray || !camerapixelarray.pixels) && !this.firstFrame) {
						console.error(`error with camerapixelarray camerapixelarray is ${camerapixelarray} and camerapixelarray.pixels is ${camerapixelarray?.pixels}`)
						this.firstFrame = false;
						return;
					}
					else {
						if (this.modelTrackingController !== null && this.modelTrackingController !== null) {
							this.modelTrackingController.updateCameraPixelArray(camerapixelarray);
							this.updateInXFrames--;
							// only runs the update every few frames as it doesn't need to constantly update
							if (this.updateInXFrames === 0 && this.trackingImage === true) {
								this.modelTrackingController.updateRenderTargetTextureFromVideo();
								if (!this.onlyUpdateOnTargetFound) this.updateInXFrames = this.updateIntervalInFrames;
							}
						}
					}
					this.firstFrame = false;
				},
			})

			const scene = document.querySelector('a-scene');

			if (scene) {
				scene.addEventListener('realityready', () => {
					if (this.modelTrackingController !== null && this.modelTrackingController) {
						// sets ids of html elements for model tracking controller
						this.modelTrackingController.targetCameraId = "camera";
						this.modelTrackingController.imageTrackingMeshId = "image-tracking-plane-sunny";
						this.modelTrackingController.final3DModelWithTextureId = "render-texture-model-sunny";
					}
				});
				scene.addEventListener("xrimagelost", (e: any) => {
					// console.log("xrimagelost", e);
					this.trackingImage = false;
					if (this.triggerToIds[e.detail.name]) {
						let currentTarget = this.triggerToIds[e.detail.name].parentElementId;
						this.attachModelToCamera(currentTarget);


					}
				});
				// scene.addEventListener("xrimageupdated", () => {
				// 	console.log("xrimageupdated");
				// });
				scene.addEventListener("xrimagefound", this.handleXRImageFound);
				scene.addEventListener("model-loaded", this.handleModelLoaded);
			}
		}
		else {
			console.log(XR8, XR8.CameraPixelArray);
		}
	}

	render() {
		const {
			loadingModelName
		} = this.state;

		const displayName = loadingModelName ? this.triggerToIds[loadingModelName].displayName : null;
		const characterNumber = loadingModelName ? this.triggerToIds[loadingModelName].characterNumber : null;


		return (
			<div className="App" >
				{ displayName !== null && characterNumber !== null &&
					<LoadingScreen
						modelName={displayName}
						modelNumber={characterNumber}
					/>
				}
				{/* <VideoDisplay /> */}
				{
					// only shows canvases when debugging is set to true
					process.env.REACT_APP_DEBUGGING === 'true' &&
					<>
						<canvas id='input-canvas' />
						<canvas id='output-canvas' />
					</>
				}
				<AFrameScene sceneHtml={this.mainScene} components={
					[
						{
							name: 'model-loading-component',
							val: ModelLoadingComponent,
						},
					]
				} />
			</div >
		);
	}
}

export default App;
